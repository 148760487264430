import request from './interceptors'

const tokenKey = "Test_Token";
const tokenUpdateTimeKey = "Test_TokenUpdateTime";
const tokenExpirationkey = "Test_TokenExpiration";
const expirationOffset = 1000 * 60 * 5; // 5min

export function autoRefreshToken() {
    if (sessionStorage.getItem(tokenKey) === null) {
        return;
    }

    getExpiration().then(expiration => {
        const lastRrfresh = getNumberFromSession(tokenUpdateTimeKey);
        const flag = lastRrfresh + expiration - expirationOffset;
        const now = new Date().getTime();
        //console.log(lastRrfresh + " last update.")
        //console.log(expiration + " expiration");
        //console.log(expirationOffset + " offset");
        //console.log(flag + " flag");
        //console.log(now + " now");
        if (now > flag) {
            console.log("begin auto refresh token.");
            request({ url: '/Token/Refresh', method: 'get' }).then(res => {
                setToken(res.toString());
            })
        }
    })
}

export async function setToken(token: string) {
    sessionStorage.setItem(tokenKey, token)

    await getExpiration().then(expiration => {
        console.log('Token expiration: ' + expiration);
        console.log('Token expiration offset: ' + expirationOffset);

        const lastRrfresh = new Date().getTime();
        sessionStorage.setItem(tokenUpdateTimeKey, lastRrfresh.toString());
        console.log('Token updated at ' + lastRrfresh);
    })
}

export function clearToken() {
    sessionStorage.removeItem(tokenKey);
    sessionStorage.removeItem(tokenUpdateTimeKey);
    sessionStorage.removeItem(tokenExpirationkey);
    console.log('Token removed.');
}

export function doGet<T>(url: string): Promise<T> {
    autoRefreshToken();
    return request({
        url: url,
        method: 'get',
    })
}

export function doPost<T>(url: string, data: any): Promise<T> {
    autoRefreshToken();
    return request({
        url: url,
        method: 'post',
        data: data
    })
}

export function get(url: string,
    success?: (res?: any) => void,
    fail?: (res?: any) => void) {
    doGet(url).then(res => {
        if (success != null) {
            success(res);
        }
    }).catch(reason => {
        if (fail != null) {
            fail(reason);
        }
    })
}

export function post(url: string, data: any,
    success?: (res?: any) => void,
    fail?: (res?: any) => void) {
    doPost(url, data).then(res => {
        if (success != null) {
            success(res);
        }
    }).catch(reason => {
        if (fail != null) {
            fail(reason);
        }
    })
}

async function getExpiration() {
    if (sessionStorage.getItem(tokenExpirationkey) === null) {
        await request({ url: '/Token/Expiration', method: 'get' }).then(res => {
            try {
                console.log('Get token expiration from server.');
                const value = Number.parseInt(res.toString());
                const expiration = value * 1000 * 60;
                sessionStorage.setItem(tokenExpirationkey, expiration.toString());
            }
            catch {
                // do nothing.
            }
        }).catch(() => {
            sessionStorage.removeItem(tokenExpirationkey);
        })
    }

    return getNumberFromSession(tokenExpirationkey);
}

function getNumberFromSession(key: string) {
    try {
        const value = sessionStorage.getItem(key);
        if (value != null) {
            return Number.parseInt(value.toString());
        }
        else {
            return -1;
        }
    } catch {
        return -1;
    }
}