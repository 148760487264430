import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";

import VXETable from 'vxe-table';
import 'vxe-table/lib/style.css';

import VxeUI from 'vxe-pc-ui';
import 'vxe-pc-ui/lib/style.css';
import zhCN from "vxe-pc-ui/lib/language/zh-CN";

import "@/router/permission";
import "@/assets/index.scss";

// 初始化第三方库配置
VxeUI.setI18n('zh-CN', zhCN);
VxeUI.setLanguage('zh-CN');

// 创建应用实例
const app = createApp(App);

// 安装插件
app.use(store)
   .use(router)
   .use(ElementPlus)
   .use(VxeUI)
   .use(VXETable);

// 注册全局组件
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component);
}

// 挂载应用
app.mount('#app');